define("discourse/plugins/paczki-interested/discourse/templates/connectors/topic-footer-main-buttons-before-create/interested", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.shouldShowField}}
    {{#if this.isInterested}}
      <InterestedButton
        {{!-- {{on "click" (fn this.handleInterested)}} --}}
        @icon="comment-medical"
        @label="interested.interested"
        @title={{this.title}}
        @disabled={{this.disabled}}
        @topicId={{this.topic.id}}
        class="btn btn-icon-text btn-default"
      />
    {{else}}
      <DButton
        @icon="comment-medical"
        @label="interested.interested"
        @title="interested.already_interested"
        @disabled="disabled"
        class="btn btn-icon-text btn-default"
      />
    {{/if}}
  {{/if}}
  */
  {
    "id": "QORAGESS",
    "block": "[[[41,[30,0,[\"shouldShowField\"]],[[[41,[30,0,[\"isInterested\"]],[[[1,\"    \"],[8,[39,1],[[24,0,\"btn btn-icon-text btn-default\"]],[[\"@icon\",\"@label\",\"@title\",\"@disabled\",\"@topicId\"],[\"comment-medical\",\"interested.interested\",[30,0,[\"title\"]],[30,0,[\"disabled\"]],[30,0,[\"topic\",\"id\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,2],[[24,0,\"btn btn-icon-text btn-default\"]],[[\"@icon\",\"@label\",\"@title\",\"@disabled\"],[\"comment-medical\",\"interested.interested\",\"interested.already_interested\",\"disabled\"]],null],[1,\"\\n\"]],[]]]],[]],null]],[],false,[\"if\",\"interested-button\",\"d-button\"]]",
    "moduleName": "discourse/plugins/paczki-interested/discourse/templates/connectors/topic-footer-main-buttons-before-create/interested.hbs",
    "isStrictMode": false
  });
});
define("discourse/plugins/paczki-interested/discourse/initializers/register-interested-small-action", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "register-interested-small-action",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8", api => {
        api.addPostSmallActionIcon("interested", "fa-comment-medical");
        api.replaceIcon("interested", "fa-comment-medical");
        api.replaceIcon("notification.interested", "fa-comment-medical");
      });
    }
  };
});
define("discourse/plugins/paczki-interested/discourse/initializers/interested-notification", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "interested-notification",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("1.3.0", api => {
        const siteSettings = container.lookup("service:site-settings");
        if (!siteSettings.bump_topic_enabled) {
          return;
        }
        api.registerNotificationTypeRenderer("interested", NotificationTypeBase => {
          return class extends NotificationTypeBase {
            icon = "comment-medical";
            label = "Interested";
          };
        }); // registerNotificationTypeRenderer

        // NOTE: Replace private message icon
        // api.replaceIcon(
        //   "envelope",
        //   "comment-medical"
        // ); // replaceIcon
      });
    }
  };
});
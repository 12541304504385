define("discourse/plugins/paczki-interested/discourse/components/interested-button", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/modifier", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/components/d-button", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _modifier, _object, _computed, _service, _dButton, _ajax, _ajaxError, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class InterestedButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "composer", [_service.service]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "disabled", [_tracking.tracked], function () {
      return false;
    }))();
    #disabled = (() => (dt7948.i(this, "disabled"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "title", [_tracking.tracked], function () {
      return "interested.interested";
    }))();
    #title = (() => (dt7948.i(this, "title"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "isEnabled", [(0, _computed.readOnly)("siteSettings.interested_enabled")]))();
    #isEnabled = (() => (dt7948.i(this, "isEnabled"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "fieldName", [(0, _computed.readOnly)("siteSettings.topic_custom_field_link")]))();
    #fieldName = (() => (dt7948.i(this, "fieldName"), void 0))();
    constructor() {
      super(...arguments);
      this.topicId = this.args.topicId;
    }
    async handleInterested() {
      const topic_id = this.topicId;
      const user_id = this.currentUser.id;
      try {
        this.disabled = true;
        await (0, _ajax.ajax)("/topic/interested", {
          type: "POST",
          data: {
            topic_id,
            user_id
          }
        });
        this.dialog.alert(_discourseI18n.default.t("interested.action_success"));
        this.title = "interested.already_interested";
      } catch (err) {
        (0, _ajaxError.popupAjaxError)(err);
      } finally {
        this.disabled = true;
      }
    }
    static #_10 = (() => dt7948.n(this.prototype, "handleInterested", [_object.action]))();
    static #_11 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
      {{#if this.isButtonVisible}}
        <DButton
          {{on "click" (fn this.handleInterested)}}
          @icon="comment-medical"
          @label="interested.interested"
          @title={{this.title}}
          @disabled={{this.disabled}}
          class="btn btn-icon-text btn-default"
        />
      {{/if}}
    
    */
    {
      "id": "QQTsGiXK",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"isButtonVisible\"]],[[[1,\"    \"],[8,[32,0],[[24,0,\"btn btn-icon-text btn-default\"],[4,[32,1],[\"click\",[28,[32,2],[[30,0,[\"handleInterested\"]]],null]],null]],[[\"@icon\",\"@label\",\"@title\",\"@disabled\"],[\"comment-medical\",\"interested.interested\",[30,0,[\"title\"]],[30,0,[\"disabled\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/paczki-interested/discourse/components/interested-button.js",
      "scope": () => [_dButton.default, _modifier.on, _helper.fn],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = InterestedButton;
});
define("discourse/plugins/paczki-interested/discourse/components/modal/interested-action", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/template", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _template, _ajax, _ajaxError, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title={{i18n "bump_topic.modal.title"}}
    @closeModal={{@closeModal}}
    @bodyClass="bump-topic-modal-body"
    class="bump-topic-modal"
  >
    <:body>
      {{html-safe this.getMessage}}
    </:body>
    <:footer>
      <DButton
        class="btn-primary"
        @action={{this.makePaczkiInterested}}
        @label="bump_topic.modal.submit"
        @disabled={{this.sending}}
      />
      <DButton
        class="btn d-modal-cancel"
        @action={{@closeModal}}
        @label="bump_topic.modal.cancel"
      />
    </:footer>
  </DModal>
  */
  {
    "id": "BChNyYq7",
    "block": "[[[8,[39,0],[[24,0,\"bump-topic-modal\"]],[[\"@title\",\"@closeModal\",\"@bodyClass\"],[[28,[37,1],[\"bump_topic.modal.title\"],null],[30,1],\"bump-topic-modal-body\"]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[1,[28,[35,2],[[30,0,[\"getMessage\"]]],null]],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,3],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\",\"@disabled\"],[[30,0,[\"makePaczkiInterested\"]],\"bump_topic.modal.submit\",[30,0,[\"sending\"]]]],null],[1,\"\\n    \"],[8,[39,3],[[24,0,\"btn d-modal-cancel\"]],[[\"@action\",\"@label\"],[[30,1],\"bump_topic.modal.cancel\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"html-safe\",\"d-button\"]]",
    "moduleName": "discourse/plugins/paczki-interested/discourse/components/modal/interested-action.hbs",
    "isStrictMode": false
  });
  class PaczkiInterestedAction extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "topic", [_tracking.tracked], function () {
      return this.args.model.topic;
    }))();
    #topic = (() => (dt7948.i(this, "topic"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "saving", [_tracking.tracked], function () {
      return false;
    }))();
    #saving = (() => (dt7948.i(this, "saving"), void 0))();
    get getMessage() {
      const message = _discourseI18n.default.t("bump_topic.modal.message");
      return (0, _template.htmlSafe)(message);
    }
    async makePaczkiInterested() {
      const topic = this.topic;
      this.saving = true;
      try {
        await (0, _ajax.ajax)(`/topic/bump/${topic.id}`, {
          type: "PUT"
        });
        this.args.closeModal();
        this.dialog.alert(_discourseI18n.default.t("bump_topic.modal.action_success"));
      } catch (err) {
        (0, _ajaxError.popupAjaxError)(err);
      } finally {
        this.saving = false;
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "makePaczkiInterested", [_object.action]))();
  }
  _exports.default = PaczkiInterestedAction;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PaczkiInterestedAction);
});
define("discourse/plugins/paczki-interested/discourse/connectors/topic-footer-main-buttons-before-create/interested", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/lib/ajax"], function (_exports, _component, _tracking, _object, _computed, _service, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BumTopic extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "composer", [_service.service]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "disabled", [_tracking.tracked], function () {
      return false;
    }))();
    #disabled = (() => (dt7948.i(this, "disabled"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "isInterested", [_tracking.tracked], function () {
      return false;
    }))();
    #isInterested = (() => (dt7948.i(this, "isInterested"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "title", [_tracking.tracked], function () {
      return "interested.already_interested";
    }))();
    #title = (() => (dt7948.i(this, "title"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "isEnabled", [(0, _computed.readOnly)("siteSettings.interested_enabled")]))();
    #isEnabled = (() => (dt7948.i(this, "isEnabled"), void 0))();
    constructor() {
      super(...arguments);
      this.topic = this.args.outletArgs.topic;
      this.checkInterested();
    }
    get isCategoryAllowed() {
      const allowedCategories = this.allowedCategories || [];
      const currentCategoryId = this.currentCategoryId;
      return allowedCategories.includes(currentCategoryId);
    }
    get isUserAllowed() {
      const topic = this.args.outletArgs.topic;
      const currentUserId = this.currentUser?.id;
      return currentUserId !== topic.user_id;
    }
    get allowedCategories() {
      return (this.siteSettings.interested_categories || "").split("|").map(id => parseInt(id, 10)).filter(id => id);
    }
    static #_10 = (() => dt7948.n(this.prototype, "allowedCategories", [(0, _object.computed)("siteSettings.interested_categories")]))();
    get currentCategoryId() {
      this.categoryId = this.composer.topic.category_id;
      return this.categoryId;
    }
    get isTopicClosed() {
      const topic = this.args.outletArgs?.topic;
      return topic?.closed || false;
    }
    get shouldShowField() {
      return this.isEnabled && this.isCategoryAllowed && this.isUserAllowed && !this.isTopicClosed;
    }

    //get shouldShowField() {
    //return this.isEnabled && this.isCategoryAllowed && this.isUserAllowed;
    //}

    async checkInterested() {
      try {
        const topic_id = this.topic.id;
        const user_id = this.currentUser.id;
        await (0, _ajax.ajax)("/topic/interested/check-pm", {
          type: "POST",
          data: {
            topic_id,
            user_id
          }
        });
        this.isInterested = true;
        this.disabled = false;
        this.title = "interested.interested";
      } catch (err) {
        this.isInterested = false;
        this.disabled = true;
        this.title = "interested.already_interested";
      }
    }
  }
  _exports.default = BumTopic;
});